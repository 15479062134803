/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.2.1/dist/css/bootstrap-reboot.min.css
 * - /npm/bootstrap@4.2.1/dist/css/bootstrap-grid.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.carousel.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.theme.default.min.css
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/native-toast@2.0.0/dist/native-toast.min.css
 * - /npm/magnific-popup@1.1.0/dist/magnific-popup.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
